import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {Health} from './station-state-dto-mapper';

@Component({
    template: require('./health-status-panel.html')
})
export class HealthStatusPanel extends Vue {

    @Prop()
    public panelName: string;

    @Prop()
    public icon: string;

    @Prop()
    private readonly healthStatus: Health | null;

    constructor() {
        super();
    }

    public get alertClass() {
        switch (this.healthStatus) {
            case Health.Unknown:
                return 'alert-secondary';
            case Health.Bad:
                return 'alert-danger';
            case Health.Degraded:
                return 'alert-warning';
            case Health.Good:
                return 'alert-success';
            default:
                return 'alert-secondary';
        }
    }

    public get alertIcon() {
        switch (this.healthStatus) {
            case Health.Unknown:
                return 'fas fa-question';
            case Health.Bad:
                return 'fa-times';
            case Health.Degraded:
                return 'fas fa-bolt';
            case Health.Good:
                return 'fa-check-square';
            default:
                return 'fas fa-question';
        }
    }

    public healthText(healthStatus: Health) {
        switch (healthStatus) {
            case Health.Unknown:
                return 'Status unknown';
            case Health.Bad:
                return 'Not operative';
            case Health.Degraded:
                return 'Not working properly';
            case Health.Good:
                return 'Operative';
            default:
                return 'Status unknown';
        }
    }
}
