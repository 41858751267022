import Vue from 'vue';
import {Inject, Prop} from 'vue-property-decorator';
import {IStationState} from './station-state-dto-mapper';
import Component from 'vue-class-component';
import {ApiClient} from './api-client';
import {Moment} from "moment";
import * as moment from "moment";

@Component({
    template: require('./danger-level-override-panel.html')
})
export class DangerLevelOverridePanel extends Vue {

    @Prop()
    public stationState: IStationState | null = null;

    @Prop()
    private dangerLevelOverrideEnabled: boolean;

    @Prop()
    private dangerLevelOverride: number;

    private dangerLevelOverrideLocal: number = 0;

    @Inject()
    public apiClient: ApiClient;

    public updateDangerLevel() {
        this.dangerLevelOverrideLocal = this.dangerLevelOverride;
    }

    public dangerLevelUp() {
        if (this.dangerLevelOverrideLocal < 5) {
            this.dangerLevelOverrideLocal++;
        }
    }

    public dangerLevelDown() {
        if (this.dangerLevelOverrideLocal > 1) {
            this.dangerLevelOverrideLocal--;
        }
    }

    public requestOverrideDangerLevel() {
        return this.apiClient.requestDangerLevelOverride(this.dangerLevelOverrideLocal);
    }

    public resetDangerLevel() {
        return this.apiClient.requestDangerLevelOverride(0);
    }

    public isToday(timestamp: Moment) {
        return timestamp.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');
    }
}

