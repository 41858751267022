import './app.scss';

import Vue from "vue";
import Component from "vue-class-component";
import {Inject, Provide} from "vue-property-decorator";
import {IAppConfig} from '../app-config';

const queryString = require('query-string');

import {StationStateTracker} from './station-state-tracker';
import {ApiClient} from './api-client';
import {ConnectionTracker} from './connection-tracker';
import {ConnectionStatePanel} from './connection-state-panel';
import {IStationState, StationStateDtoMapper} from './station-state-dto-mapper';
import {StationStateLogsPanel} from './station-state-logs-panel';
import {StationStateLogsDtoMapper} from './station-state-logs-dto-mapper';
import {HealthStatusPanel} from './health-status-panel';
import {DangerLevelOverridePanel} from './danger-level-override-panel';

@Component({
    template: require('./app.html'),
    components: {
        HealthStatusPanel,
        DangerLevelOverridePanel,
        ConnectionStatePanel,
        StationStateLogsPanel
    }
})
export default class App extends Vue {

    @Inject()
    private appConfig: IAppConfig;

    @Provide()
    private apiClient: ApiClient;

    @Provide()
    private readonly connectionTracker: ConnectionTracker;

    @Provide()
    private stationStateTracker: StationStateTracker;

    @Provide()
    private readonly stationStateLogsDtoMapper: StationStateLogsDtoMapper;

    private stationState: IStationState | null = null;

    private dangerLevelOverrideEnabled = false;

    constructor() {
        super();
        let query = queryString.parse(location.search);

        let stationStateDtoMapper = new StationStateDtoMapper();

        this.stationStateLogsDtoMapper = new StationStateLogsDtoMapper(stationStateDtoMapper);

        this.connectionTracker = ConnectionTracker.Create(this.appConfig.stationLinkBaseUrl, query.apiToken);
        this.stationStateTracker = new StationStateTracker(this.connectionTracker, stationStateDtoMapper, this.stationStateLogsDtoMapper);
        this.apiClient = new ApiClient(this.appConfig.stationLinkBaseUrl, query.apiToken);

        this.apiClient.getPermissions().then(p => this.enablePermissionBasedFeatures(p));

        this.stationStateTracker.registerStationStateCallback((update: IStationState, initialState: boolean) => this.updateStationState(update));
    }

    private updateStationState(state: IStationState) {
        this.stationState = state;
    }

    private enablePermissionBasedFeatures(permissions: string[]) {
        this.dangerLevelOverrideEnabled = permissions.indexOf('HolmbukturaOverrideDangerLevel') !== -1;
    }
}

